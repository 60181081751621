import React from 'react';
import { Button, Grid, GridSize, CircularProgress, Typography } from '@mui/material';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { OrdersAutoTreatContext } from '../contexts/OrdersAutoTreatContext';
import PaginationBanner from '../../../modules/shared/machineManagement/PaginationBanner';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface OrdersAutoTreatFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  tableFilters?: any;
  setTableFilters?: any;
}

const OrdersAutoTreatFilterBar: React.FC<OrdersAutoTreatFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    tableFilters,
    setTableFilters,
  } = props;

  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);

  const [onPremPage, setOnPremPage] = React.useState<number>(0);

  const {
    orders,
    fetchOrdersAutoTreat,
    fetchCachedOrdersAutoTreat,
    fetchOrdersOptionsAutoTreat,
    fetchCachedOrdersOptionsAutoTreat,
    ordersLoading,
    ordersRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
    handleStatusFilterChange,
    statusFilter,
    handleCompleteFilterChange,
    completeFilter,
    handleApiLinkedFilterChange,
    apiLinkedFilter,
  } = React.useContext(OrdersAutoTreatContext);
  const { autoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    if (orders.length === 0 && localStorage.getItem('machineManagementAutoTreatMachine')) {
      fetchCachedOrdersAutoTreat(localStorage.getItem('machineManagementAutoTreatMachine'));
      fetchCachedOrdersOptionsAutoTreat(localStorage.getItem('machineManagementAutoTreatMachine'));
      fetchOrdersAutoTreat(
        localStorage.getItem('machineManagementAutoTreatMachine'),
        genericFilter,
        statusFilter,
        completeFilter,
        apiLinkedFilter,
      );
      fetchOrdersOptionsAutoTreat(localStorage.getItem('machineManagementAutoTreatMachine'));
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem('machineManagementAutoTreatMachine')) {
      setActiveMachine(
        autoTreatMachines.filter(
          (choice) => choice?.value === localStorage.getItem('machineManagementAutoTreatMachine'),
        )?.[0],
      );
    }
  }, [autoTreatMachines]);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('machineManagementAutoTreatMachine', value);
    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
    fetchCachedOrdersAutoTreat(value);
    fetchCachedOrdersOptionsAutoTreat(value);
    fetchOrdersAutoTreat(value, genericFilter, statusFilter, completeFilter, apiLinkedFilter);
    fetchOrdersOptionsAutoTreat(value);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const refreshDisabled = () => {
    return (
      ordersLoading ||
      activeMachine?.value === 'Select A Machine' ||
      !activeMachine?.subscription_active ||
      activeMachine?.online_status === 'offline'
    );
  };
  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'online',
                ),
                'Offline Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'offline',
                ),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={statusFilter}
              choices={[
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
                { label: 'All', value: 'All' },
              ]}
              onChange={(value) => handleStatusFilterChange(value)}
              label="Status"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={completeFilter}
              choices={[
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' },
                { label: 'All', value: 'All' },
              ]}
              onChange={(value) => handleCompleteFilterChange(value)}
              label="Complete"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={apiLinkedFilter}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' },
              ]}
              onChange={(value) => handleApiLinkedFilterChange(value)}
              label="API Linked"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={refreshDisabled()}
              onClick={() => {
                fetchOrdersAutoTreat(
                  activeMachine?.value,
                  genericFilter,
                  statusFilter,
                  completeFilter,
                  apiLinkedFilter,
                );
                fetchOrdersOptionsAutoTreat(activeMachine?.value);
              }}
            >
              {ordersRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!ordersLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {ordersLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(ordersRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {orders?.length === 500 && (
            <PaginationBanner
              totalRecords={orders?.length}
              currentPage={onPremPage}
              onPageChange={(newPage) => setOnPremPage(newPage)}
            />
          )}
        </Grid>
      </Grid>

      <br />
      <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
        Live Filters
      </Typography>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.name}
              onChange={(value) => setTableFilters({ ...tableFilters, name: value })}
              label="Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.operation}
              onChange={(value) => setTableFilters({ ...tableFilters, operation: value })}
              label="Customer"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                setTableFilters({});
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrdersAutoTreatFilterBar;
