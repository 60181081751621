import React from 'react';
import axios from '../../utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../utils/env';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface MachineDevicesAutoTreatContextInterface {
  fetchMachineDevicesAutoTreat?: (machineSerial: string) => Promise<void>;
  machineDevices?: any[];
  machineDevicesLoading?: boolean;

  fetchMachineInputDevicesAutoTreat?: (machineSerial: string) => Promise<void>;
  machineInputDevices?: any[];
  machineInputDevicesLoading?: boolean;

  fetchMachineOutputDevicesAutoTreat?: (machineSerial: string) => Promise<void>;
  machineOutputDevices?: any[];
  machineOutputDevicesLoading?: boolean;
}

const MachineDevicesAutoTreatContext = React.createContext<MachineDevicesAutoTreatContextInterface>({});

const MachineDevicesAutoTreatContextConsumer = MachineDevicesAutoTreatContext.Consumer;
const MachineDevicesAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar } = React.useContext(SnackBarContext);

  const [machineDevices, setMachineDevices] = React.useState([]);
  const [machineDevicesLoading, setMachineDevicesLoading] = React.useState(true);
  const [machineInputDevices, setMachineInputDevices] = React.useState([]);
  const [machineInputDevicesLoading, setMachineInputDevicesLoading] = React.useState(true);
  const [machineOutputDevices, setMachineOutputDevices] = React.useState([]);
  const [machineOutputDevicesLoading, setMachineOutputDevicesLoading] = React.useState(true);

  const fetchMachineDevicesAutoTreat = async (machineSerial: string) => {
    setMachineDevicesLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/machine_devices/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/machine_devices/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setMachineDevicesLoading(false);
              setMachineDevices(response.data.results);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load Devices');
                setMachineDevicesLoading(false);
                setMachineDevices([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchMachineInputDevicesAutoTreat = async (machineSerial: string) => {
    setMachineInputDevicesLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/machine_input_devices/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/machine_input_devices/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setMachineInputDevicesLoading(false);

              // Overwrite 'DigitalInputID' with its numeric value
              const transformedData = response.data.results.map((item) => ({
                ...item,
                DigitalInputID: parseInt(item.DigitalInputID, 10), // Convert and overwrite
              }));

              setMachineInputDevices(transformedData); // Use the transformed data
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load Devices');
                setMachineInputDevicesLoading(false);
                setMachineDevices([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchMachineOutputDevicesAutoTreat = async (machineSerial: string) => {
    setMachineOutputDevicesLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/machine_output_devices/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/machine_output_devices/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setMachineOutputDevicesLoading(false);

              // Overwrite 'DigitalOutputID' with its numeric value
              const transformedData = response.data.results.map((item) => ({
                ...item,
                DigitalOutputID: parseInt(item.DigitalOutputID, 10), // Convert and overwrite
              }));

              setMachineOutputDevices(transformedData); // Use the transformed data
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load Devices');
                setMachineOutputDevicesLoading(false);
                setMachineDevices([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <MachineDevicesAutoTreatContext.Provider
      value={{
        fetchMachineDevicesAutoTreat,
        machineDevices,
        machineDevicesLoading,

        fetchMachineInputDevicesAutoTreat,
        machineInputDevices,
        machineInputDevicesLoading,

        fetchMachineOutputDevicesAutoTreat,
        machineOutputDevices,
        machineOutputDevicesLoading,
      }}
    >
      {children}
    </MachineDevicesAutoTreatContext.Provider>
  );
};

export {
  MachineDevicesAutoTreatContextProvider,
  MachineDevicesAutoTreatContextConsumer,
  MachineDevicesAutoTreatContext,
};
