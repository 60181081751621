/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import DriversAutoBulkDetail from './DriversAutoBulkDetailPage';
import DriversAutoBulkCreate from './DriversAutoBulkCreate';
import DriversAutoBulkList from './DriversAutoBulkList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const DriversAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchDriversAutoBulk, detailPage, creatingPage, driversLoading, activeMachine } =
    React.useContext(DriversAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchDriversAutoBulk]);

  const autoBulkDriverPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <DriversAutoBulkDetail />,
          createPage: <DriversAutoBulkCreate />,
          listPage: <DriversAutoBulkList />,
        }[autoBulkDriverPage()]
      }
    </>
  );
};

export default DriversAutoBulkPage;
