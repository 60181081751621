/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RfidsAutoBulkContext } from '../contexts/RfidAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import RfidsAutoBulkDetail from './RfidAutoBulkDetailPage';
import RfidsAutoBulkCreate from './RfidAutoBulkCreate';
import RfidsAutoBulkList from './RfidAutoBulkList';
import MachineStatusBanner from '../../../modules/shared/machineManagement/MachineStatusBanner';

const RfidAutoBulkPage: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);

  const { fetchRfidsAutoBulk, detailPage, creatingPage, activeMachine } = React.useContext(RfidsAutoBulkContext);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchRfidsAutoBulk]);

  const autoBulkRfidPage = () => {
    if (detailPage) {
      return 'updatePage';
    }

    if (creatingPage) {
      return 'createPage';
    }

    return 'listPage';
  };

  return (
    <>
      <MachineStatusBanner activeMachine={activeMachine} />
      {
        {
          updatePage: <RfidsAutoBulkDetail />,
          createPage: <RfidsAutoBulkCreate />,
          listPage: <RfidsAutoBulkList />,
        }[autoBulkRfidPage()]
      }
    </>
  );
};

export default RfidAutoBulkPage;
