/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid, GridSize, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import PaginationBanner from '../../../modules/shared/machineManagement/PaginationBanner';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface ProductsAutoBulkFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  tableFilters?: any;
  setTableFilters?: any;
}

const ProductsAutoBulkFilterBar: React.FC<ProductsAutoBulkFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    tableFilters,
    setTableFilters,
  } = props;
  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);
  const [onPremPage, setOnPremPage] = React.useState<number>(0);

  const {
    products,
    fetchProductsAutoBulk,
    fetchProductOptions,
    fetchCachedProductOptions,
    fetchCachedProductsAutoBulk,
    productsLoading,
    productsRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
    handleStatusFilterChange,
    statusFilter,
    handleApiLinkedFilterChange,
    apiLinkedFilter,
  } = React.useContext(ProductsAutoBulkContext);
  const { autoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    if (products.length === 0 && localStorage.getItem('machineManagementAutoBulkMachine')) {
      fetchCachedProductsAutoBulk(localStorage.getItem('machineManagementAutoBulkMachine'));
      fetchCachedProductOptions(localStorage.getItem('machineManagementAutoBulkMachine'));
      fetchProductsAutoBulk(
        localStorage.getItem('machineManagementAutoBulkMachine'),
        genericFilter,
        statusFilter,
        apiLinkedFilter,
      );
      fetchProductOptions(localStorage.getItem('machineManagementAutoBulkMachine'));
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem('machineManagementAutoBulkMachine')) {
      const storedValue = localStorage.getItem('machineManagementAutoBulkMachine');
      const filteredMachine = autoBulkMachines.find((choice) => choice?.value === storedValue);
      setActiveMachine(filteredMachine);
    }
  }, [autoBulkMachines]);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('machineManagementAutoBulkMachine', value);
    fetchCachedProductsAutoBulk(value);
    fetchCachedProductOptions(value);
    fetchProductsAutoBulk(value, genericFilter, statusFilter, apiLinkedFilter);
    fetchProductOptions(value);
    setActiveMachine(autoBulkMachines.filter((choice) => choice?.value === value)?.[0]);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const refreshDisabled = () => {
    return (
      productsLoading ||
      activeMachine?.value === 'Select A Machine' ||
      !activeMachine?.subscription_active ||
      activeMachine?.online_status === 'offline'
    );
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'online',
                ),
                'Offline Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'offline',
                ),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={statusFilter}
              choices={[
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
                { label: 'All', value: 'All' },
              ]}
              onChange={(value) => handleStatusFilterChange(value)}
              label="Status"
            />
          </Grid>

          <Grid item xs={1}>
            <SelectInput
              value={apiLinkedFilter}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' },
              ]}
              onChange={(value) => handleApiLinkedFilterChange(value)}
              label="API Linked"
            />
          </Grid>

          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={refreshDisabled()}
              onClick={() => {
                fetchProductsAutoBulk(activeMachine?.value, genericFilter, statusFilter, apiLinkedFilter);
                fetchProductOptions(activeMachine?.value);
              }}
            >
              {productsRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!productsLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {productsLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(productsRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {products?.length === 500 && (
            <PaginationBanner
              totalRecords={products?.length}
              currentPage={onPremPage}
              onPageChange={(newPage) => setOnPremPage(newPage)}
            />
          )}
        </Grid>
      </Grid>

      <br />
      <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
        Live Filters
      </Typography>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.product_name}
              onChange={(value) => setTableFilters({ ...tableFilters, product_name: value })}
              label="Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.product_description}
              onChange={(value) => setTableFilters({ ...tableFilters, product_description: value })}
              label="Description"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.product_type}
              onChange={(value) => setTableFilters({ ...tableFilters, product_type: value })}
              label="Type"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                setTableFilters({});
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductsAutoBulkFilterBar;
