/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { SeedAutoTreatFormValues } from '../model';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import { SeedsAutoTreatValidationsContext } from '../contexts/SeedsAutoTreatValidationsContext';
import seedRanges from '../constants/seedRanges';
import seedSelectOptions from '../constants/seedSelectOptions';

interface SeedsAutoTreatFormProps {
  seed: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const SeedsAutoTreatForm: React.FC<SeedsAutoTreatFormProps> = (props) => {
  const { seed, machineSerial, onSuccess, onBack } = props;

  const {
    fetchOptions,
    options,
    updateSeedAutoTreat,
    createSeedAutoTreat,
    deleteSeedAutoTreat,
    seedDeleting,
    seedSubmitting,
    activeMachine,
  } = React.useContext(SeedsAutoTreatContext);
  const { validateSeedsFields, formErrors } = React.useContext(SeedsAutoTreatValidationsContext);
  const [formValues, setFormValues] = React.useState<SeedAutoTreatFormValues>({});
  const [amountTypeChoices, setAmountTypeChoices] = React.useState([]);
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const calculateSeedCountDefault = (crop_id) => {
    if (activeMachine.metric) {
      return seedRanges[crop_id]?.defautSeedSizeMetric;
    }

    return seedRanges[crop_id]?.defautSeedSizeImperial;
  };

  const calculateUnitSizeCountDefault = (crop_id) => {
    return seedRanges[crop_id]?.defaultUnitSizeCount;
  };

  React.useEffect(() => {
    const abSeedsFieldsValid = validateSeedsFields(formValues);
    setActionButtonDisabled(!abSeedsFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    fetchOptions();
  }, []);

  React.useEffect(() => {
    setFormValues({
      seed_variety_id: seed?.seed_variety_id,
      variety: seed?.variety,
      seed_lot_data: seed?.seed_lot_data,
      seed_available: seed?.seed_available,
      shipment_number: seed?.shipment_number,
      seed_variety_desc: seed?.seed_variety_desc,
      seed_cnt_per_scale_uow: seed?.seed_cnt_per_scale_uow || calculateSeedCountDefault(seed?.crop_id || 1),
      crop_id: seed?.crop_id || 1,
      supplier_id: seed?.supplier_id || options?.supplier_choices?.[0]?.value,
      supplier_brand_id: seed?.supplier_brand_id || options?.brand_choices?.[0]?.value,
      supplier_brand: seed?.supplier_brand,
      unit_size_wt: seed?.unit_size_wt || '50',
      unit_size_cnt: seed?.unit_size_cnt || calculateUnitSizeCountDefault(seed?.crop_id || 1),
      seed_amount_type_id: seed?.seed_amount_type_id || '3',
      seed_origin: seed?.seed_origin,
      seed_germ: seed?.seed_germ,
      seed_test_date: seed?.seed_test_date,
      seed_notes: seed?.seed_notes,
      active: seed?.active || 'True',
    });
  }, [seed, options]);

  React.useEffect(() => {
    const amountTypesFilterArray =
      seedSelectOptions?.crop_data?.[
        Object.prototype.hasOwnProperty.call(seedSelectOptions.crop_data, formValues.crop_id) ? formValues.crop_id : '7'
      ]?.amount_types;

    if (options && options.amount_type_choices && Array.isArray(options.amount_type_choices)) {
      const filteredAmountTypes = options.amount_type_choices.filter(
        (option) => amountTypesFilterArray && amountTypesFilterArray.includes(option.value),
      );

      setAmountTypeChoices(filteredAmountTypes);
    }
  }, [formValues.crop_id]);

  const handleCropChange = (cropId) => {
    // If crop is soybeans (id=1), corn (id=4), or cotton (id=5) default to
    // Seed Count Unit (id=3) other wise default to Weight Unit (id=2)

    if (['1', '4', '5'].includes(cropId.toString())) {
      setFormValues({
        ...formValues,
        crop_id: cropId,
        seed_amount_type_id: '3',
        seed_cnt_per_scale_uow: calculateSeedCountDefault(cropId),
        unit_size_cnt: calculateUnitSizeCountDefault(cropId),
      });
    } else {
      setFormValues({
        ...formValues,
        crop_id: cropId,
        seed_amount_type_id: '2',
        seed_cnt_per_scale_uow: calculateSeedCountDefault(cropId),
        unit_size_cnt: calculateUnitSizeCountDefault(cropId),
      });
    }
  };

  const handleUpdate = () => {
    updateSeedAutoTreat(seed.seed_variety_id, formValues, activeMachine.value);
  };

  const handleCreate = () => {
    createSeedAutoTreat(formValues, activeMachine.value, onSuccess);
  };

  const handleDelete = () => {
    deleteSeedAutoTreat(formValues.seed_variety_id);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.variety}
              onChange={(value) => setFormValues({ ...formValues, variety: value })}
              label="Variety"
              required
              errorMessage={formErrors?.seed_variety}
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_variety_desc}
              onChange={(value) => setFormValues({ ...formValues, seed_variety_desc: value })}
              label="Description"
              errorMessage={formErrors?.seed_variety_desc}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              value={formValues?.crop_id}
              choices={options?.crop_choices}
              onChange={handleCropChange}
              label="Crop"
              required
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              value={formValues?.supplier_id}
              choices={options?.supplier_choices}
              onChange={(value) => {
                setFormValues({ ...formValues, supplier_id: value });
              }}
              label="Company"
              required
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              value={formValues?.supplier_brand_id}
              choices={options?.brand_choices}
              onChange={(value) => {
                setFormValues({ ...formValues, supplier_brand_id: value });
              }}
              label="Seed Brand"
              required
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_available}
              onChange={(value) => {
                setFormValues({ ...formValues, seed_available: value });
              }}
              label={`Inventory ${formValues?.seed_amount_type_id?.toString() === '1' ? '(Weight)' : '(Units)'}`}
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_lot_data}
              onChange={(value) => setFormValues({ ...formValues, seed_lot_data: value })}
              label="Lot #"
              errorMessage={formErrors?.seed_lot_data}
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.shipment_number}
              onChange={(value) => setFormValues({ ...formValues, shipment_number: value })}
              label="Batch #"
              errorMessage={formErrors?.Seedshipment_number}
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_origin}
              onChange={(value) => setFormValues({ ...formValues, seed_origin: value })}
              label="Seed Origin"
              errorMessage={formErrors?.seed_origin}
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_germ?.toString()}
              onChange={(value) => {
                setFormValues({ ...formValues, seed_germ: value });
              }}
              label="Seed Germ"
              errorMessage={formErrors?.seed_germ}
              endAdornment="%"
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_test_date}
              onChange={(value) => setFormValues({ ...formValues, seed_test_date: value })}
              label="Seed Test Date"
            />
          </Grid>
          <Grid item xs={5}>
            <FormInput
              value={formValues?.seed_notes}
              onChange={(value) => setFormValues({ ...formValues, seed_notes: value })}
              multiline
              errorMessage=""
              rows={null}
              maxRows={5}
              label="Seed Notes"
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              value={formValues?.seed_amount_type_id}
              choices={amountTypeChoices}
              onChange={(value) => {
                setFormValues({ ...formValues, seed_amount_type_id: value });
              }}
              label="Amount Type"
              required
            />
          </Grid>
          {['1', '4', '5'].includes(formValues?.crop_id?.toString()) && (formValues.seed_amount_type_id = '3') && (
            <>
              <Grid item xs={5}>
                <FormInput
                  value={formValues?.seed_cnt_per_scale_uow}
                  onChange={(value) => {
                    setFormValues({ ...formValues, seed_cnt_per_scale_uow: value });
                  }}
                  label={activeMachine.metric ? 'Seeds/kg' : 'Seeds/lb'}
                  errorMessage={formErrors?.seed_cnt_per_scale_uow}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={5}>
                <FormInput
                  value={formValues?.unit_size_cnt}
                  onChange={(value) => {
                    setFormValues({ ...formValues, unit_size_cnt: value });
                  }}
                  label="CNT unit"
                />
              </Grid>
            </>
          )}

          {formValues?.seed_amount_type_id !== '3' && (
            <Grid item xs={5}>
              <FormInput
                value={formValues?.unit_size_wt}
                onChange={(value) => {
                  setFormValues({ ...formValues, unit_size_wt: value });
                }}
                label="Weight unit"
                errorMessage={formErrors?.unit_size_wt}
              />
            </Grid>
          )}
          <Grid item xs={5}>
            <SelectInput
              value={formValues?.active}
              choices={[
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' },
              ]}
              onChange={(value) => setFormValues({ ...formValues, active: value })}
              label="Active"
            />
          </Grid>
          <br />
        </Grid>
        <br />
        {seed?.seed_variety_id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={
              actionButtonDisabled || seedSubmitting || seedDeleting || activeMachine?.online_status === 'offline'
            }
          >
            {seedSubmitting ? 'Updating...' : 'Update'}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreate}
            disabled={
              actionButtonDisabled || seedSubmitting || seedDeleting || activeMachine?.online_status === 'offline'
            }
          >
            {seedSubmitting ? 'Creating...' : 'Create'}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={onBack}
          style={{ marginLeft: 8 }}
          disabled={seedDeleting || activeMachine?.online_status === 'offline'}
        >
          Cancel
        </Button>
      </Grid>
      {formValues?.seed_variety_id && (
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={seedSubmitting || seedDeleting || activeMachine?.online_status === 'offline'}
          >
            {seedDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SeedsAutoTreatForm;
