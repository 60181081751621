/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { WorkOrdersAutoBulkContext } from '../contexts/WorkOrdersAutoBulkContext';
import WorkOrderAutoBulkForm from '../components/WorkOrdersAutoBulkForm';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

const WorkOrdersAutoBulkDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, activeWorkOrderAutoBulk, activeMachine } = React.useContext(WorkOrdersAutoBulkContext);

  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const { fetchAutoBulkMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);

        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded]);

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine?.label}`,
    },
    {
      onClick: () => null,
      text: `${activeWorkOrderAutoBulk?.work_order_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => {
              setDetailPage(false);
            }}
          >
            ← Return to Order List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WorkOrderAutoBulkForm
            workOrder={activeWorkOrderAutoBulk}
            machineSerial={activeMachine?.value}
            onSuccess={() => setDetailPage(false)}
          />
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default WorkOrdersAutoBulkDetailPage;
