import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import { SelectOption } from '../../../modules/shared/model';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { SeedIntegrationsAutoTreatContext } from '../contexts/SeedsIntegrationsAutoTreatContext';
import { Machine } from '../../../modules/machinesAdminManagement/model';

interface IntegrationFormProps {
  activeMachine: Machine;
  seedVarietyId: any;
  seedIntegration: any;
  setShowIntegrationForm: any;
  machineSerial?: any;
  partnerChoices?: SelectOption[];
}

const IntegrationForm: React.FC<IntegrationFormProps> = (props) => {
  const { activeMachine, seedVarietyId, seedIntegration, setShowIntegrationForm, machineSerial, partnerChoices } =
    props;

  const [formValues, setFormValues] = React.useState<any>({});

  const {
    createSeedIntegrationAutoTreat,
    updateSeedIntegrationAutoTreat,
    deleteSeedIntegrationAutoTreat,
    seedIntegrationsSubmitting,
  } = React.useContext(SeedIntegrationsAutoTreatContext);

  React.useEffect(() => {
    setFormValues({
      integration_id: seedIntegration?.integration_id,
      seed_variety_id: seedIntegration?.seed_variety_id || seedVarietyId,
      api_partner: seedIntegration?.api_partner,
      partner_record_id_1: seedIntegration?.partner_record_id_1,
      partner_record_id_2: seedIntegration?.partner_record_id_2,
      partner_record_id_3: seedIntegration?.partner_record_id_3,
    });
  }, [seedIntegration]);

  const handleUpdate = () => {
    updateSeedIntegrationAutoTreat(machineSerial, seedVarietyId, seedIntegration?.integration_id, formValues);
  };

  const handleCreate = () => {
    createSeedIntegrationAutoTreat(machineSerial, formValues.seed_variety_id, formValues);
  };

  const handleDelete = () => {
    setShowIntegrationForm(false);
    deleteSeedIntegrationAutoTreat(machineSerial, seedVarietyId, seedIntegration?.integration_id);
  };

  const handleBack = () => {
    setShowIntegrationForm(false);
  };

  return (
    partnerChoices && (
      <Box style={{ padding: 0 }}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item xs={8} md={12}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SelectInput
                        choices={partnerChoices}
                        value={formValues?.api_partner}
                        onChange={(value) => setFormValues({ ...formValues, api_partner: value })}
                        label="API Partner"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_1}
                        onChange={(value) => {
                          setFormValues({ ...formValues, partner_record_id_1: value });
                        }}
                        label="Partner Record ID 1"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_2}
                        onChange={(value) => setFormValues({ ...formValues, partner_record_id_2: value })}
                        label="Partner Record ID 2"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_3}
                        onChange={(value) => setFormValues({ ...formValues, partner_record_id_3: value })}
                        label="Partner Record ID 3"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {formValues.integration_id && (
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right', marginTop: '-90px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDelete}
                      disabled={activeMachine?.online_status === 'offline'}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {seedIntegration?.integration_id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        seedIntegrationsSubmitting ||
                        !formValues.api_partner ||
                        !formValues.partner_record_id_1 ||
                        activeMachine?.online_status === 'offline'
                      }
                      onClick={handleUpdate}
                    >
                      {seedIntegrationsSubmitting ? 'Updating Seed Integration...' : 'Update Seed Integration'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        seedIntegrationsSubmitting ||
                        !formValues.api_partner ||
                        !formValues.partner_record_id_1 ||
                        activeMachine?.online_status === 'offline'
                      }
                      onClick={handleCreate}
                    >
                      {seedIntegrationsSubmitting ? 'Creating Seed Integration...' : 'Create Seed Integration'}
                    </Button>
                  )}
                  <Button variant="outlined" style={{ marginLeft: 7 }} onClick={handleBack}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default IntegrationForm;
