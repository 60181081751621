/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { ProductAutoBulkFormValues } from '../model';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import { ProductsAutoBulkValidationsContext } from '../contexts/ProductsAutoBulkValidationsContext';

interface ProductsAutoBulkFormProps {
  product: any;
  machineSerial?: string;
  onSuccess?: () => void;
  onBack?: () => void;
}

const ProductsAutoBulkForm: React.FC<ProductsAutoBulkFormProps> = (props) => {
  const { product, machineSerial, onSuccess, onBack } = props;

  const {
    updateProductAutoBulk,
    createProductAutoBulk,
    deleteProductAutoBulk,
    productDeleting,
    productSubmitting,
    activeMachine,
    productsOptions,
  } = React.useContext(ProductsAutoBulkContext);
  const { validateProductsAutoBulkFields, formErrors } = React.useContext(ProductsAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<ProductAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleIsolatedChange = (isolatedHandle) => {
    if (['false'].includes(isolatedHandle.toString())) {
      setFormValues({
        ...formValues,
        isolated_load_last: 'False',
        isolated: isolatedHandle,
      });
    } else {
      setFormValues({
        ...formValues,
        isolated_load_last: 'False',
        isolated: isolatedHandle,
      });
    }
  };

  const handleUpdate = () => {
    updateProductAutoBulk(product.product_id, formValues);
  };

  const handleCreate = () => {
    createProductAutoBulk(formValues, false);
  };

  const handleCreateAndRedirect = () => {
    createProductAutoBulk(formValues, true);
  };

  const handleDelete = () => {
    deleteProductAutoBulk(formValues.product_id);
  };

  React.useEffect(() => {
    const abProductsFieldsValid = validateProductsAutoBulkFields(formValues);
    setActionButtonDisabled(!abProductsFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      product_id: product?.product_id,
      product_name: product?.product_name,
      product_description: product?.product_description,
      product_type_id: product?.product_type_id || 'Liquid',
      product_type: product?.product_type || 'Liquid',
      product_priority: product?.product_priority,
      product_shipment: product?.product_shipment,
      product_inventory: product?.product_inventory,
      // amount_type_id: product?.amount_type_id,
      product_inventory_unit: product?.product_inventory_unit || 'gallon',
      product_density: product?.product_density || '',
      product_density_type: product?.product_density_type || 'lb/gal',
      product_epa: product?.product_epa,
      product_msds: product?.product_msds,
      product_comment: product?.product_comment,
      isolated: product?.isolated || 'False',
      isolated_load_last: product?.isolated_load_last,
      source_name: product?.source_name,
      source_id: product?.source_id,
      user_name: product?.user_name,
      active: product?.active || 'True',
      date_and_time: product?.date_and_time,
    });
  }, [product]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_name}
                onChange={(value) => setFormValues({ ...formValues, product_name: value })}
                label="Name"
                errorMessage={formErrors.productName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_description}
                onChange={(value) => setFormValues({ ...formValues, product_description: value })}
                label="Description"
                errorMessage={formErrors.Description}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.product_type}
                choices={productsOptions.product_type_choices}
                onChange={(value) => setFormValues({ ...formValues, product_type: value })}
                label="Product Type"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_shipment}
                onChange={(value) => setFormValues({ ...formValues, product_shipment: value })}
                label="Shipment"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_inventory}
                onChange={(value) => setFormValues({ ...formValues, product_inventory: value })}
                label="Inventory"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.product_inventory_unit}
                choices={productsOptions.amount_type_choices}
                onChange={(value) => setFormValues({ ...formValues, product_inventory_unit: value })}
                label="Inventory Amount Type"
                errorMessage={formErrors?.productInventoryUnitError}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_density}
                onChange={(value) => setFormValues({ ...formValues, product_density: value })}
                label="Density"
                disabled={formValues?.product_type === 'Dry'}
                errorMessage={formValues?.product_type === 'Liquid' ? formErrors.Density : ''}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput value="lb/gal" onChange={(value) => null} label="Density Type" disabled />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.isolated}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={handleIsolatedChange}
                label="Isolated"
              />
            </Grid>
            {formValues?.isolated === 'True' && (
              <Grid item xs={5}>
                <SelectInput
                  value={formValues?.isolated_load_last}
                  choices={[
                    { label: 'Yes', value: 'True' },
                    { label: 'No', value: 'False' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, isolated_load_last: value })}
                  label="Isolated Load Last"
                />
              </Grid>
            )}
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_epa}
                onChange={(value) => setFormValues({ ...formValues, product_epa: value })}
                label="EPA"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_msds}
                onChange={(value) => setFormValues({ ...formValues, product_msds: value })}
                label="MSDS"
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.product_comment}
                onChange={(value) => setFormValues({ ...formValues, product_comment: value })}
                label="Comment"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />

          {activeMachine ? (
            <>
              {product?.product_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    actionButtonDisabled ||
                    productSubmitting ||
                    productDeleting ||
                    activeMachine?.online_status === 'offline'
                  }
                  onClick={handleUpdate}
                >
                  {productSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <>
                  {productSubmitting ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={productSubmitting || actionButtonDisabled || activeMachine?.online_status === 'offline'}
                      onClick={handleCreate}
                    >
                      {productSubmitting ? 'Creating...' : 'Create and add another'}
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={actionButtonDisabled || activeMachine?.online_status === 'offline'}
                        onClick={handleCreate}
                      >
                        {productSubmitting ? 'Creating...' : 'Create and add another'}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={actionButtonDisabled || activeMachine?.online_status === 'offline'}
                        style={{ marginLeft: 8 }}
                        onClick={handleCreateAndRedirect}
                      >
                        {productSubmitting ? 'Creating...' : 'Create and view'}
                      </Button>
                    </>
                  )}
                </>
              )}
              <Button variant="contained" color="primary" onClick={onBack} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary">
              Select A Machine
            </Button>
          )}
        </form>
      </Grid>
      {formValues?.product_id && (
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={productDeleting || productSubmitting || activeMachine?.online_status === 'offline'}
          >
            {productDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductsAutoBulkForm;
