import { IDataTableColumn } from '../../dataTable/DataTable/model';

const deviceDisplayColumns: IDataTableColumn<any>[] = [
  {
    name: 'Software Serial',
    selector: 'SoftwareSerial',
    sortable: true,
  },
  {
    name: 'Device Name',
    selector: 'DeviceName',
    sortable: true,
  },
  {
    name: 'Active',
    selector: 'Active',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'DeviceDesc',
    sortable: true,
  },
  {
    name: 'Global Device ID',
    selector: 'GlobalDeviceID',
    sortable: true,
  },
  {
    name: 'Comm Type ID',
    selector: 'CommTypeID',
    sortable: true,
    sortFunction: (rowA: any, rowB: any): number => {
      const idA = parseInt(rowA.CommTypeID, 10); // Convert string to number
      const idB = parseInt(rowB.CommTypeID, 10); // Convert string to number
      return idA - idB; // Sort numerically
    },
  },
  {
    name: 'Device Map Tag',
    selector: 'DeviceMapTag',
    sortable: true,
    sortFunction: (rowA: any, rowB: any): number => {
      const idA = parseInt(rowA.DeviceMapTag, 10); // Convert string to number
      const idB = parseInt(rowB.DeviceMapTag, 10); // Convert string to number
      return idA - idB; // Sort numerically
    },
  },
  {
    name: 'Digital Input Name ID',
    selector: 'DigitalInputName_ID',
    sortable: true,
  },
  {
    name: 'Digital Input Name ID2',
    selector: 'DigitalInputName_ID2',
    sortable: true,
  },
  {
    name: 'Digital Output Name ID',
    selector: 'DigitalOutputName_ID',
    sortable: true,
  },
  {
    name: 'Digital Output Name ID2',
    selector: 'DigitalOutputName_ID2',
    sortable: true,
  },
  {
    name: 'Digital Output Name Reset ID',
    selector: 'DigitalOutputName_ResetID',
    sortable: true,
  },
];

export default deviceDisplayColumns;
