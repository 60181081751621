import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,
  Grid,
  InputAdornment,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'light' ? '#eaecef' : '#30363d',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
    '&.Mui-focused fieldset': {
      boxShadow: `${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'} 0px 0px 0px 2px`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px !important',
    fontSize: 14,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)', // Adjust the icon position vertically
  },
}));

interface User {
  label: string;
  value: string;
}

interface UserAccessSelectorProps {
  users: User[];
  selectedUsers: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  loading: boolean; // Add this line
}

const UserAccessSelector: React.FC<UserAccessSelectorProps> = ({ users, selectedUsers, setSelectedUsers, loading }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      inputValue &&
      !users.some((user) => user?.label?.toLowerCase() === inputValue?.toLowerCase())
    ) {
      const newUser = { label: `External User: ${inputValue}`, value: inputValue };
      setSelectedUsers([...selectedUsers, newUser]); // Select the newly added user
      setInputValue(''); // Reset input field
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Box id="machine-selector-container">
      <Autocomplete
        multiple
        PaperComponent={({ children }) => (
          <Paper style={{ border: '1px solid #ccc', borderTop: '2px solid #ccc' }}>{children}</Paper>
        )}
        options={users}
        value={selectedUsers}
        onChange={(event, newValue) => {
          setSelectedUsers(newValue);
        }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li
            key={option.value}
            onClick={props.onClick}
            onMouseOver={props.onMouseOver}
            className={props.className}
            style={{ fontSize: '13px' }}
          >
            <Checkbox style={{ marginRight: 8 }} checked={selectedUsers.some((user) => user.value === option.value)} />
            {option.label}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={option.value}
              label={option.label}
              onDelete={getTagProps({ index }).onDelete}
              deleteIcon={<CloseIcon />}
            />
          ))
        }
        renderInput={(params) => (
          <StyledTextField
            label=""
            onKeyDown={handleKeyDown}
            placeholder="Select Users"
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
              style: { padding: '20px' },
            }}
            inputRef={params.InputProps.ref}
            inputProps={params.inputProps}
            variant="outlined"
            fullWidth
          />
        )}
        disableCloseOnSelect
        inputValue={inputValue}
        disablePortal
        fullWidth
      />
    </Box>
  );
};

export default UserAccessSelector;
