/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import Breadcrumbs from '../../../modules/navigation/components/Breadcrumbs';
import TabPanel from '../../../modules/shared/tabs/TabPanel';
import DriversAutoBulkDetail from '../components/DriversAutoBulkDetail';
import URLTabs from '../../../modules/shared/tabs/URLTabs';

const DriversAutoBulkDetailPage: React.FunctionComponent = () => {
  const { setDetailPage, setActiveDriverAutoBulk, activeDriverAutoBulk, activeMachine } =
    React.useContext(DriversAutoBulkContext);

  const tabNames = ['details'];
  const baseUrl = `/drivers_auto_bulk/${activeDriverAutoBulk.driver_id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  const handleBack = () => {
    setDetailPage(false);
    setActiveDriverAutoBulk(null);
  };

  const links = [
    {
      onClick: () => setDetailPage(false),
      text: `${activeMachine.value}`,
    },
    {
      onClick: () => null,
      text: `${activeDriverAutoBulk.driver_name}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }} onClick={handleBack}>
            ← Return to Driver List
          </Typography>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="machine tabs">
          <Tab style={{ textTransform: 'capitalize' }} label="Details" />
        </Tabs>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <DriversAutoBulkDetail />
          </TabPanel>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default DriversAutoBulkDetailPage;
