import React, { Dispatch, SetStateAction } from 'react';
import { AlertColor } from '@mui/material';

interface MachineManagementResponse {
  sql_endpoint: string;
  status: 'Success' | 'Error';
  message: string;
  table: string;
  record_id: string;
}

interface SnackBarContextInterface {
  showMachineManagementSnackBar?: (response: MachineManagementResponse) => Promise<void>;
  showInvalidFormatSnackBar?: (message: string) => Promise<void>;
  showInfoSnackBar?: (message: string) => Promise<void>;
  showErrorSnackBar?: (message: string) => Promise<void>;
  showSuccessSnackBar?: (message: string) => Promise<void>;
  setSnackBarOpen?: Dispatch<SetStateAction<boolean>>;
  snackBarOpen?: boolean;
  setSnackBarMessage?: Dispatch<SetStateAction<string>>;
  snackBarMessage?: string;
  handleSnackbarAlertSeverityChange?: any;
  snackbarAlertSeverity?: any;
}

const SnackBarContext = React.createContext<SnackBarContextInterface>({});

const SnackBarContextConsumer = SnackBarContext.Consumer;
const SnackBarContextProvider: React.FC = ({ children }) => {
  const [snackBarOpen, setSnackBarOpen] = React.useState<any>(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<any>('');
  const [snackbarAlertSeverity, setSnackbarAlertSeverity] = React.useState<AlertColor>('success');

  const handleSnackbarAlertSeverityChange = (status) => {
    if (status.toLocaleLowerCase() === 'success') {
      setSnackbarAlertSeverity('success');
    }
    if (status.toLocaleLowerCase() === 'error') {
      setSnackbarAlertSeverity('error');
    }
    if (status.toLocaleLowerCase() === 'info') {
      setSnackbarAlertSeverity('info');
    }
    if (status.toLocaleLowerCase() === 'warning') {
      setSnackbarAlertSeverity('warning');
    }
  };

  const showSuccessSnackBar = async (message: string) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    handleSnackbarAlertSeverityChange('success');
  };

  const showErrorSnackBar = async (message: string) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    handleSnackbarAlertSeverityChange('error');
  };

  const showInvalidFormatSnackBar = async (message: string) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    handleSnackbarAlertSeverityChange('error');
  };

  const showMachineManagementSnackBar = async (response: MachineManagementResponse) => {
    if (response.status === 'Success') {
      showSuccessSnackBar(`${response.message}`);
    } else if (response.status === 'Error') {
      showErrorSnackBar(`${response.message}`);
    } else {
      showInvalidFormatSnackBar('Unknown error occured');
    }
  };

  const showInfoSnackBar = async (message: string) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    handleSnackbarAlertSeverityChange('info');
  };

  return (
    <SnackBarContext.Provider
      value={{
        showMachineManagementSnackBar,
        showInvalidFormatSnackBar,
        showInfoSnackBar,
        showErrorSnackBar,
        showSuccessSnackBar,

        snackBarOpen,
        setSnackBarOpen,

        snackBarMessage,
        setSnackBarMessage,

        snackbarAlertSeverity,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

export { SnackBarContextProvider, SnackBarContextConsumer, SnackBarContext };
