import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { SelectOption } from '../../../modules/shared/model';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { CustomerIntegrationsAutoBulkContext } from '../contexts/CustomerIntegrationsAutoBulkContext';
import { Machine } from '../../../modules/machinesAdminManagement/model';

interface IntegrationFormProps {
  activeMachine: Machine;
  CustomerId: any;
  customerIntegration: any;
  setShowIntegrationForm: any;
  machineSerial: any;
  partnerChoices: SelectOption[];
}

const IntegrationForm: React.FC<IntegrationFormProps> = (props) => {
  const { activeMachine, customerIntegration, setShowIntegrationForm, machineSerial, partnerChoices, CustomerId } =
    props;

  const {
    updateCustomerIntegrationAutoBulk,
    createCustomerIntegrationAutoBulk,
    deleteCustomerIntegrationAutoBulk,
    customerIntegrationsSubmitting,
  } = React.useContext(CustomerIntegrationsAutoBulkContext);

  const [formValues, setFormValues] = React.useState<any>({});
  const [initialLoad, setInitialLoad] = React.useState(false);

  React.useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);

      setFormValues({
        integration_id: customerIntegration?.integration_id,
        customer_id: customerIntegration?.customer_id || CustomerId,
        api_partner: customerIntegration?.api_partner,
        partner_record_id_1: customerIntegration?.partner_record_id_1,
        partner_record_id_2: customerIntegration?.partner_record_id_2,
        partner_record_id_3: customerIntegration?.partner_record_id_3,
      });
    }
  }, []);

  const handleUpdate = () => {
    updateCustomerIntegrationAutoBulk(
      machineSerial,
      customerIntegration.customer_id,
      customerIntegration?.integration_id,
      formValues,
    );
    setShowIntegrationForm(true);
  };

  const handleCreate = () => {
    createCustomerIntegrationAutoBulk(machineSerial, formValues.customer_id, formValues);
  };

  const handleDelete = () => {
    setShowIntegrationForm(false);
    deleteCustomerIntegrationAutoBulk(machineSerial, formValues.customer_id, formValues.integration_id);
  };

  const handleBack = () => {
    setShowIntegrationForm(false);
  };

  return (
    partnerChoices && (
      <Box style={{ padding: 0 }}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item xs={8} md={12}>
            <form noValidate autoComplete="off">
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SelectInput
                        choices={partnerChoices}
                        value={formValues?.api_partner}
                        onChange={(value) => setFormValues({ ...formValues, api_partner: value })}
                        label="API Partner"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_1}
                        onChange={(value) => setFormValues({ ...formValues, partner_record_id_1: value })}
                        label="Partner Record ID 1"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_2}
                        onChange={(value) => setFormValues({ ...formValues, partner_record_id_2: value })}
                        label="Partner Record ID 2"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        value={formValues?.partner_record_id_3}
                        onChange={(value) => setFormValues({ ...formValues, partner_record_id_3: value })}
                        label="Partner Record ID 3"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {formValues.integration_id && (
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right', marginTop: '-90px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDelete}
                      disabled={activeMachine?.online_status === 'offline'}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {customerIntegration?.integration_id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        customerIntegrationsSubmitting ||
                        !formValues.api_partner ||
                        !formValues.partner_record_id_1 ||
                        activeMachine?.online_status === 'offline'
                      }
                      onClick={handleUpdate}
                    >
                      {customerIntegrationsSubmitting
                        ? 'Updating Customer Integration...'
                        : 'Update Customer Integration'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        customerIntegrationsSubmitting ||
                        !formValues.api_partner ||
                        !formValues.partner_record_id_1 ||
                        activeMachine?.online_status === 'offline'
                      }
                      onClick={handleCreate}
                    >
                      {customerIntegrationsSubmitting
                        ? 'Creating Customer Integration...'
                        : 'Create Customer Integration'}
                    </Button>
                  )}
                  <Button variant="outlined" style={{ marginLeft: 7 }} onClick={handleBack}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default IntegrationForm;
