/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import { TransportAutoBulkFormValues } from '../model';
import { TransportsAutoBulkContext } from '../contexts/TransportsAutoBulkContext';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { TransportsAutoBulkValidationsContext } from '../contexts/TransportsAutoBulkValidationsContext';

interface TransportsAutoBulkFormProps {
  transport: any;
  onSuccess?: () => void;
}

const TransportsAutoBulkForm: React.FC<TransportsAutoBulkFormProps> = (props) => {
  const { transport, onSuccess } = props;

  const { activeMachine, transportSubmitting, transportOptions, createTransportAutoBulk, updateTransportAutoBulk } =
    React.useContext(TransportsAutoBulkContext);
  const { validateTransportsAutoBulkFields, formErrors } = React.useContext(TransportsAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<TransportAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

  const handleUpdate = () => {
    updateTransportAutoBulk(formValues);
  };

  const handleCreate = () => {
    createTransportAutoBulk(formValues, onSuccess);
  };

  React.useEffect(() => {
    const abCustomersFieldsValid = validateTransportsAutoBulkFields(formValues);
    setActionButtonDisabled(!abCustomersFieldsValid);
  }, [formValues]);

  React.useEffect(() => {
    setFormValues({
      transport_id: transport.transport_id,
      transport_name: transport.transport_name,
      transport_number: transport.transport_number,
      transport_empty_wt: transport.transport_empty_wt,
      transport_max_wt: transport.transport_max_wt,
      transport_company_id: transport.transport_company_id,
      transport_company_name: transport.transport_company_name,
      transport_comment: transport.transport_comment,
      rfid_id: transport.rfid_id,
      rfid_name: transport.rfid_name,
      active: transport.active || 'True',
      transport_api_partner: transport.transport_api_partner,
      flow_control_id: transport.flow_control_id,
      flow_control_name: transport.flow_control_name,
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={8}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.transport_name}
                onChange={(value) => setFormValues({ ...formValues, transport_name: value })}
                label="Transport Name"
                errorMessage={formErrors.TransportName}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.transport_number}
                onChange={(value) => setFormValues({ ...formValues, transport_number: value })}
                label="Transport Number"
                errorMessage={formErrors.TransportNumber}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.transport_empty_wt}
                onChange={(value) => setFormValues({ ...formValues, transport_empty_wt: value })}
                label="Transport Empty Weight"
                errorMessage={formErrors.TransportEmptyWeight}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.transport_max_wt}
                onChange={(value) => setFormValues({ ...formValues, transport_max_wt: value })}
                label="Transport Max Weight"
                errorMessage={formErrors.TransportMaxWeight}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.transport_comment}
                onChange={(value) => setFormValues({ ...formValues, transport_comment: value })}
                label="Comment"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.transport_company_id}
                choices={transportOptions.transportCompanyOptions}
                onChange={(value) => {
                  setFormValues({ ...formValues, transport_company_id: value });
                }}
                label="Transport Company"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.rfid_id}
                groupedChoices={{
                  'Not assigned': transportOptions.transportRFIDOptions.filter(
                    (option) => option.associated_transport_id === '' && option.associated_driver_id === '',
                  ),
                  'Assigned Transport': transportOptions.transportRFIDOptions
                    .filter((option) => option.associated_transport_id !== '')
                    .map((option) => ({
                      ...option,
                      label: `${option.label} ----> ${option.associated_transport_name}`,
                    })),
                  'Assigned Driver': transportOptions.transportRFIDOptions
                    .filter((option) => option.associated_driver_id !== '')
                    .map((option) => ({
                      ...option,
                      label: `${option.label} ----> ${option.associated_driver_name}`,
                    })),
                }}
                // choices={transportOptions.transportRFIDOptions.filter(
                //   (option) => option.transport_id === formValues.transport_id || (option.transport_id === '' && option.driver_id === ''),
                // )}
                onChange={(value) => setFormValues({ ...formValues, rfid_id: value })}
                label="RFID"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.active}
                choices={[
                  { label: 'Yes', value: 'True' },
                  { label: 'No', value: 'False' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, active: value })}
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          <>
            {transport?.transport_id ? (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || transportSubmitting || activeMachine?.online_status === 'offline'}
                onClick={handleUpdate}
              >
                {transportSubmitting ? 'Updating Transport...' : 'Update Transport'}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={actionButtonDisabled || transportSubmitting || activeMachine?.online_status === 'offline'}
                onClick={handleCreate}
              >
                {transportSubmitting ? 'Creating Transport...' : 'Create Transport'}
              </Button>
            )}
          </>
        </form>
      </Grid>
    </Grid>
  );
};

export default TransportsAutoBulkForm;
