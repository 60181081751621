import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, Grid, FormControlLabel, Checkbox, TextField, Typography } from '@mui/material';
import FormInput from '../../../modules/shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import PolarisLabel from '../../../modules/shared/form/PolarisLabel';
import { WorkOrderAutoBulkFormValues } from '../model';
import { WorkOrdersAutoBulkContext } from '../contexts/WorkOrdersAutoBulkContext';
import WorkOrderAutoBulkItems from './WorkOrdersItemsAutoBulkForm';
import WorkOrderAutoBulkStatusBanner from './WorkOrderAutoBulkStatusBanner';

interface WorkOrdersAutoBulkFormProps {
  workOrder: any;
  machineSerial?: string;
  onSuccess?: () => void;
}

const WorkOrdersAutoBulkForm: React.FC<WorkOrdersAutoBulkFormProps> = ({ workOrder, machineSerial, onSuccess }) => {
  const {
    activeMachine,
    workOrderSubmitting,
    createOrUpdateWorkOrderAutoBulk,
    deleteWorkOrderAutoBulk,
    workOrderDeleting,
    workOrderOptions,
  } = React.useContext(WorkOrdersAutoBulkContext);

  const [formValues, setFormValues] = useState<WorkOrderAutoBulkFormValues>({});
  const [autoGenerateOrderName, setAutoGenerateOrderName] = useState(false);
  const [includeArea, setIncludeArea] = useState(false);
  const [isOrderStarted, setIsOrderStarted] = useState(false);
  const [apiLinked, setApiLinked] = useState(true);

  const findCustomerDetails = () => {
    const customer = workOrderOptions.customer_choices?.find((c) => c.value === formValues.customer_id);
    return customer
      ? {
          customer_contact_first: customer.customer_contact_first,
          customer_contact_last: customer.customer_contact_last,
        }
      : { customer_contact_first: '', customer_contact_last: '' };
  };

  const findDriverDetails = () => {
    const driver = workOrderOptions.driver_choices?.find((c) => c.value === formValues.driver_id);
    return driver
      ? { driver_phone: driver.driver_phone, rfid_assigned: driver.rfid_assigned, comment: driver.comment }
      : { driver_phone: '', rfid_assigned: '', comment: '' };
  };

  const findTransportDetails = () => {
    const transport = workOrderOptions.transport_choices?.find((t) => t.value === formValues.transport_id);
    return transport
      ? {
          transport_number: transport.transport_number,
          rfid_assigned: transport.rfid_assigned,
          comment: transport.comment,
        }
      : { transport_number: '', rfid_assigned: '', comment: '' };
  };

  useEffect(() => {
    setFormValues({
      work_order_id: workOrder.work_order_id,
      work_order_name: workOrder.work_order_name || 'Auto Generate',
      work_order_description: workOrder.work_order_description,
      work_order_comment: workOrder.work_order_comment,
      work_order_po_number: workOrder.work_order_po_number,
      customer_id: workOrder.customer_id,
      work_order_by_area: workOrder.work_order_by_area,
      work_order_by_area_total: workOrder.work_order_by_area_total,
      date_and_time_last_modified: workOrder.date_and_time_last_modified,
      active: workOrder.active,
      transport_id: workOrder.transport_id,
      driver_id: workOrder.driver_id,
      work_order_items: workOrder.work_order_items,
    });
    setIsOrderStarted(!!workOrder?.pu_date_and_time);

    // Convert the string value of api_linked to a boolean.
    // This is necessary because api_linked is received as a string ("true" or "false"),
    // and needs to be explicitly compared to 'true' to get the correct boolean value.
    setApiLinked(workOrder.api_linked?.toLowerCase() === 'true');
  }, [workOrder]);

  const handleAutoGenerateCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoGenerateOrderName(event.target.checked);
    if (event.target.checked) {
      setFormValues({ ...formValues, work_order_name: 'Auto Generate' });
    } else {
      setFormValues({ ...formValues, work_order_name: '' });
    }
  };

  const handleIncludeAreaCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeArea(event.target.checked);
  };

  const handleUpdate = () => {
    createOrUpdateWorkOrderAutoBulk(activeMachine.value, formValues, onSuccess);
  };

  const handleCreate = () => {
    createOrUpdateWorkOrderAutoBulk(activeMachine.value, formValues, onSuccess);
  };

  const handleDelete = () => {
    deleteWorkOrderAutoBulk(formValues.work_order_id);
  };

  const setItemFormValues = (newItems) => {
    setFormValues({ ...formValues, work_order_items: newItems });
  };

  const alphabetizedOptions = (options) => {
    const sortedOptions = options?.sort((a, b) => {
      if (a.label?.toLowerCase()?.trim() < b.label?.toLowerCase()?.trim()) {
        return -1; // a comes first
      }
      if (a.label?.toLowerCase()?.trim() > b.label?.toLowerCase()?.trim()) {
        return 1; // b comes first
      }
      return 0; // a and b are equal
    });

    return sortedOptions || options;
    // return options
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={11}>
        <WorkOrderAutoBulkStatusBanner isOrderStarted={isOrderStarted} isOrderAPI={apiLinked} />
        <form noValidate autoComplete="off">
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={5}>
              {!workOrder.work_order_id && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoGenerateOrderName}
                      onChange={handleAutoGenerateCheckboxChange}
                      name="autoGenerateOrderName"
                    />
                  }
                  label="Auto Generate Order Name"
                />
              )}
              <FormInput
                value={formValues?.work_order_name}
                onChange={(value) => setFormValues({ ...formValues, work_order_name: value })}
                label="Order Name"
                disabled={autoGenerateOrderName || isOrderStarted || apiLinked}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.work_order_description}
                onChange={(value) => setFormValues({ ...formValues, work_order_description: value })}
                label="Order Descripton"
                disabled={isOrderStarted || apiLinked}
              />
            </Grid>
            <Grid item xs={5}>
              <FormInput
                value={formValues?.work_order_comment}
                onChange={(value) => setFormValues({ ...formValues, work_order_comment: value })}
                label="Order Comment"
                disabled={isOrderStarted || apiLinked}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                {' '}
                <br />{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                <br />{' '}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <PolarisLabel label="Customer" htmlFor="customer" />
              <Autocomplete
                disablePortal
                id="customer"
                getOptionLabel={(option: any) => `${option.label} (id: ${option.value})`}
                options={alphabetizedOptions(workOrderOptions.customer_choices)}
                disabled={isOrderStarted || apiLinked}
                style={{ padding: 0 }}
                sx={{ width: '100%', padding: 0 }}
                renderInput={(params) => <TextField {...params} style={{ padding: 0 }} label="" />}
                value={
                  workOrderOptions.customer_choices.find((option) => option.value === formValues.customer_id) || null
                }
                onChange={(event, newValue) => setFormValues({ ...formValues, customer_id: newValue?.value })}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                First Name: {findCustomerDetails().customer_contact_first}
              </Typography>
              <Typography variant="body2">Last Name: {findCustomerDetails().customer_contact_last}</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.transport_id}
                onChange={(value) => setFormValues({ ...formValues, transport_id: value })}
                label="Transport"
                choices={workOrderOptions.transport_choices}
                disabled={isOrderStarted || apiLinked}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                Transport Number: {findTransportDetails().transport_number}{' '}
              </Typography>
              <Typography variant="body2">RFID Assigned: {findTransportDetails().rfid_assigned}</Typography>
              <Typography variant="body2">Comment: {findTransportDetails().comment}</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                value={formValues?.driver_id}
                onChange={(value) => setFormValues({ ...formValues, driver_id: value })}
                label="Driver"
                choices={workOrderOptions.driver_choices}
                disabled={isOrderStarted || apiLinked}
              />
              <Typography variant="body2" style={{ marginTop: '8px' }}>
                Phone Number: {findDriverDetails().driver_phone}
              </Typography>
              <Typography variant="body2">RFID Assigned: {findDriverDetails().rfid_assigned}</Typography>
              <Typography variant="body2">Comment: {findDriverDetails().comment}</Typography>
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox checked={includeArea} onChange={handleIncludeAreaCheckboxChange} name="includeArea" />
                }
                label="Include Area"
              />
              {includeArea && (
                <FormInput
                  value={formValues?.work_order_by_area_total}
                  onChange={(value) => setFormValues({ ...formValues, work_order_by_area_total: value })}
                  label="Area Total"
                  disabled={isOrderStarted || apiLinked}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <WorkOrderAutoBulkItems
                rowsProp={formValues?.work_order_items}
                fieldOptions={workOrderOptions?.product_choices}
                setFormValueItems={setItemFormValues}
                datagridDisabled={isOrderStarted || apiLinked}
              />
            </Grid>
            <Grid item xs={12}>
              {workOrder.work_order_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={
                    workOrderSubmitting ||
                    isOrderStarted ||
                    apiLinked ||
                    !formValues.work_order_items ||
                    workOrderDeleting ||
                    activeMachine?.online_status === 'offline'
                  }
                >
                  {workOrderSubmitting ? 'Updating...' : 'Update'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreate}
                  disabled={
                    workOrderSubmitting ||
                    isOrderStarted ||
                    apiLinked ||
                    !formValues.work_order_items ||
                    activeMachine?.online_status === 'offline'
                  }
                >
                  {workOrderSubmitting ? 'Creating...' : 'Create'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
      {formValues?.work_order_id && (
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={
              workOrderSubmitting ||
              isOrderStarted ||
              apiLinked ||
              !formValues.work_order_items ||
              workOrderDeleting ||
              activeMachine?.online_status === 'offline'
            }
          >
            {workOrderDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default WorkOrdersAutoBulkForm;
