/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { ProductsAutoBulkContext } from '../contexts/ProductsAutoBulkContext';
import { ProductAutoBulk } from '../model';
import { AuthContext } from '../../../modules/auth/contexts/AuthContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import ProductAutoBulkFilterBar from '../components/ProductsAutoBulkFilterBar';
import SelectInput from '../../../modules/shared/form/SelectInput';

const ProductsAutoBulkList: React.FunctionComponent = () => {
  const history = useHistory();
  const [activePartner, setActivePartner] = React.useState<string>(null);
  const [partnerModalOpen, setPartnerModalOpen] = React.useState(false);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const [userHasManageIntegrationRecords, setUserHasManageIntegrationRecords] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState({
    product_name: '',
    product_description: '',
    product_type: '',
  });
  const {
    products,
    setCreatingPage,
    setDetailPage,
    setActiveProductAutoBulk,
    activeMachine,
    setActiveMachine,
    productsOptions,
  } = React.useContext(ProductsAutoBulkContext);
  const { autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);
  const { currentUserHasUserPermission } = React.useContext(AuthContext);

  React.useEffect(() => {
    const manageIntegrationRecords = sessionStorage.getItem('manageIntegrationRecords');

    if (manageIntegrationRecords?.toLowerCase() === 'true') {
      setUserHasManageIntegrationRecords(true);
    } else {
      setUserHasManageIntegrationRecords(false);
    }
  }, []);

  const filterProducts = () => {
    if (tableFilters.product_name || tableFilters.product_description || tableFilters.product_type) {
      const nameFilteredProducts = products.filter((product) =>
        product.product_name?.toLowerCase().includes(tableFilters.product_name?.toLowerCase()),
      );

      const descriptionFilteredProducts = nameFilteredProducts.filter(
        (product) =>
          product.product_description?.toLowerCase().includes(tableFilters.product_description?.toLowerCase()) ||
          product.product_comment?.toLowerCase().includes(tableFilters.product_description?.toLowerCase()),
      );

      const typeFilteredProducts = descriptionFilteredProducts.filter((product) =>
        product.product_type?.toLowerCase().includes(tableFilters.product_type?.toLowerCase()),
      );

      return typeFilteredProducts;
    }

    return products;
  };

  const handlePartnerModalClose = () => {
    setPartnerModalOpen(false);
    setActivePartner(null);
  };

  const handlePartnerModalCloseAndRedirect = () => {
    setPartnerModalOpen(false);
    history.push(`/autobulk/machines/${activeMachine?.value}/partners/products_import?api_partner=${activePartner}`);
  };

  const buttonsDisabled = () => {
    return (
      activeMachine?.value === 'Select A Machine' ||
      activeMachine?.value === undefined ||
      !activeMachine?.subscription_active ||
      activeMachine?.online_status === 'offline'
    );
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Product Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <ProductAutoBulkFilterBar
              machineChoices={autoBulkMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        {partnerModalOpen && (
          <Dialog
            open={partnerModalOpen}
            onClose={handlePartnerModalClose}
            maxWidth="md"
            PaperProps={{ style: { width: '40vw' } }} // Custom width
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h2>
                  <strong>Please Select An API Partner</strong>
                </h2>
                <br />
                <Grid item xs={12} md={12}>
                  <SelectInput
                    choices={(activeMachine?.api_partners as any).map((partner) => ({
                      label: partner.global_api_partner_id,
                      value: partner.global_api_partner_id,
                    }))}
                    value={activePartner}
                    onChange={(value) => setActivePartner(value)}
                    label="API Partner"
                  />
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePartnerModalCloseAndRedirect} color="primary" autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Products List
                </Typography>
              </Grid>
              <Grid item>
                {currentUserHasUserPermission('company_super_admin') && (
                  <>
                    {activeMachine?.manage_integration_records &&
                      Object.keys(activeMachine.api_partners || {}).length > 0 &&
                      userHasManageIntegrationRecords && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 7 }}
                            disabled={buttonsDisabled()}
                            onClick={() => {
                              setPartnerModalOpen(true);
                            }}
                          >
                            Bulk Import For API Partner
                          </Button>
                        </>
                      )}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 7 }}
                      disabled={buttonsDisabled()}
                      onClick={() => {
                        history.push(`/autobulk/machines/${activeMachine?.value}/products_import`);
                      }}
                    >
                      Bulk Import
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={buttonsDisabled()}
                  onClick={() => {
                    setCreatingPage(true);
                    setFitlerBarActive(false);
                    setActiveMachine(activeMachine);
                  }}
                >
                  Create Product
                </Button>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterProducts()}
                defaultSortField="product_name"
                defaultSortAsc
                striped
                onRowClicked={(row: ProductAutoBulk) => {
                  setActiveProductAutoBulk(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                  // history.push(`/autobulk/machines/${activeMachine.value}/products/${row.product_id}`)
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default ProductsAutoBulkList;
