import React from 'react';
import { Button, Grid, GridSize, CircularProgress, Typography } from '@mui/material';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { TransactionApiLogsAutoTreatContext } from '../contexts/TransactionApiLogsAutoTreatContext';
import PaginationBanner from '../../../modules/shared/machineManagement/PaginationBanner';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface TransactionApiLogsAutoTreatFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  tableFilters?: any;
  setTableFilters?: any;
}

const TransactionApiLogsAutoTreatFilterBar: React.FC<TransactionApiLogsAutoTreatFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    tableFilters,
    setTableFilters,
  } = props;

  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);
  const [onPremPage, setOnPremPage] = React.useState<number>(0);

  const {
    transactionApiLogsAutoTreat,
    fetchTransactionApiLogsAutoTreat,
    fetchCachedTransactionApiLogsAutoTreat,
    transactionApiLogsAutoTreatLoading,
    transactionApiLogsAutoTreatRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
  } = React.useContext(TransactionApiLogsAutoTreatContext);
  const { autoTreatMachines } = React.useContext(MachinesContext);

  React.useEffect(() => {
    if (transactionApiLogsAutoTreat.length === 0 && localStorage.getItem('machineManagementAutoTreatMachine')) {
      fetchCachedTransactionApiLogsAutoTreat(localStorage.getItem('machineManagementAutoTreatMachine'));
      fetchTransactionApiLogsAutoTreat(localStorage.getItem('machineManagementAutoTreatMachine'), '');
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem('machineManagementAutoTreatMachine')) {
      setActiveMachine(
        autoTreatMachines.filter(
          (choice) => choice?.value === localStorage.getItem('machineManagementAutoTreatMachine'),
        )?.[0],
      );
    }
  }, [autoTreatMachines]);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('machineManagementAutoTreatMachine', value);
    fetchCachedTransactionApiLogsAutoTreat(value);
    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const refreshDisabled = () => {
    return (
      transactionApiLogsAutoTreatLoading ||
      activeMachine?.value === 'Select A Machine' ||
      !activeMachine?.subscription_active ||
      activeMachine?.online_status === 'offline'
    );
  };

  const handleLoadData = () => {
    fetchTransactionApiLogsAutoTreat(activeMachine?.value, genericFilter);
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'online',
                ),
                'Offline Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'offline',
                ),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={refreshDisabled()}
              onClick={() => {
                fetchTransactionApiLogsAutoTreat(activeMachine?.value, genericFilter);
              }}
            >
              {transactionApiLogsAutoTreatRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!transactionApiLogsAutoTreatLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {transactionApiLogsAutoTreatLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(transactionApiLogsAutoTreatRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {transactionApiLogsAutoTreat?.length === 500 && (
            <PaginationBanner
              totalRecords={transactionApiLogsAutoTreat?.length}
              currentPage={onPremPage}
              onPageChange={(newPage) => setOnPremPage(newPage)}
            />
          )}
        </Grid>
      </Grid>

      <br />
      <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
        Live Filters
      </Typography>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.orderName}
              onChange={(value) => setTableFilters({ ...tableFilters, orderName: value })}
              label="Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.orderPartnerID}
              onChange={(value) => setTableFilters({ ...tableFilters, orderPartnerID: value })}
              label="Partner ID"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.orderVariety}
              onChange={(value) => setTableFilters({ ...tableFilters, orderVariety: value })}
              label="Variety"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.orderRecipe}
              onChange={(value) => setTableFilters({ ...tableFilters, orderRecipe: value })}
              label="Recipe"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.orderResponse}
              onChange={(value) => setTableFilters({ ...tableFilters, orderResponse: value })}
              label="Response"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() =>
                setTableFilters({
                  ...tableFilters,
                  orderName: '',
                  orderPartnerID: '',
                  orderVariety: '',
                  orderRecipe: '',
                  orderResponse: '',
                })
              }
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransactionApiLogsAutoTreatFilterBar;
